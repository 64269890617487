import { createContext, useRef, useState } from "react";
import Img1 from '../assets/gallery8.jpg'

export const Dcontext = createContext(null)


function DataContext(props) {

    const modalRef = useRef(null)
    const [renderImages, setRenderImages] = useState(Img1)
    const [renderImageIndex, setRenderImageIndex] = useState(0)


    const renderImg = (imgData, i) => {
        setRenderImageIndex(i)
        setRenderImages(imgData)
        if(modalRef.current){
            modalRef.current.classList.remove('d-none')
            modalRef.current.classList.add('d-block')
            document.body.classList.add("overflow-hidden")
        }
    }

    const closeRenderedImg = () => {
        if(modalRef.current){
            modalRef.current.classList.remove('d-block')
            modalRef.current.classList.add('d-none')
            document.body.classList.remove("overflow-hidden")
        }
        setRenderImages(null)
    }




    const clientFeedback = [
        {
            name: "Mrs. Kiruthika Sounderrajan",
            project: "DTCP Unapproved Layout",
            title: "Highly recommended!",
            message: "Very happy with the layout design. The team was professional and completed the work on time. Highly recommended!",
            rating: 5,
            imgname: "Kiruthika+Sounderrajan"
        },
        {
            name: "Mr. Gowrishankar",
            project: "DTCP Unapproved Layout",
            title: "Great guidance!",
            message: "Excellent service! They guided me well through the entire layout process. Thank you for your great work.",
            rating: 5,
            imgname: "Gowrishankar"
        },
        {
            name: "Mr. Gurumoorthi",
            project: "DTCP Unapproved Layout",
            title: "Perfectly planned!",
            message: "Really satisfied with their work. The layout was planned perfectly, and their attention to detail is commendable.",
            rating: 4,
            imgname: "Gurumoorthi"
        },
        {
            name: "Mr. Sathyaseelan",
            project: "DTCP Unapproved Layout",
            title: "Superb effort!",
            message: "Superb team effort! They ensured everything was taken care of without any stress for me. Very satisfied!",
            rating: 5,
            imgname: "Sathyaseelan"
        },
        {
            name: "Mr. Thangavel",
            project: "3D Designing",
            title: "Fantastic designs!",
            message: "Fantastic designs! The 3D visuals were clear, and they helped me visualize my dream project perfectly.",
            rating: 5,
            imgname: "Thangavel"
        },
        {
            name: "Mr. Sabari",
            project: "3D Designing",
            title: "Creative and quick!",
            message: "Loved their creativity and dedication. The designs were amazing, and I was impressed with their quick turnaround.",
            rating: 5,
            imgname: "Sabari"
        },
        {
            name: "Mr. Vignesh",
            project: "3D Designing",
            title: "Brought ideas to life!",
            message: "Beautiful 3D work by the team. They brought my ideas to life exactly as I had imagined.",
            rating: 5,
            imgname: "Vignesh"
        },
        {
            name: "Ms. Gopika",
            project: "3D Designing",
            title: "Highly professional!",
            message: "Highly professional team! The 3D designs were so detailed, and I am very happy with the outcome.",
            rating: 5,
            imgname: "Gopika"
        },
        {
            name: "Mr. K.R. Vignesh",
            project: "Blueprint",
            title: "Spot-on blueprints!",
            message: "Blueprints were spot on! The team delivered exactly what I needed, and I am very happy with their service.",
            rating: 5,
            imgname: "K.R.+Vignesh"
        },
        {
            name: "Mr. Bharath",
            project: "Blueprint",
            title: "Responsive team!",
            message: "The team was so responsive and understood all my requirements. The blueprint quality was excellent.",
            rating: 4,
            imgname: "Bharath"
        },
        {
            name: "Mr. Mahendran",
            project: "Blueprint",
            title: "Wonderful experience!",
            message: "Wonderful experience working with the team. They made sure the blueprint was perfect for my project.",
            rating: 4,
            imgname: "Mahendran"
        },
        {
            name: "Mr. Manikandan",
            project: "Blueprint",
            title: "Perfect drafting!",
            message: "Perfectly drafted blueprints. The team is very skilled, and I would recommend them to anyone in need of such services.",
            rating: 5,
            imgname: "Manikandan"
        },
        {
            name: "Mrs. Kalyani Sekar",
            project: "Blueprint",
            title: "Impressed!",
            message: "Very impressed with their professionalism and attention to detail. The blueprint was exactly as I had hoped for.",
            rating: 5,
            imgname: "Kalyanisekar"
        },
        {
            name: "Mrs. Angulakshmi",
            project: "Blueprint",
            title: "Careful and precise!",
            message: "They delivered the blueprint with utmost care and precision. I am very pleased with their service.",
            rating: 5,
            imgname: "Angulakshmi"
        },
        {
            name: "Mr. Parasuraman",
            project: "Structural Designing",
            title: "Perfect designs!",
            message: "The structural design was perfect and well-suited to my needs. A big thanks to the entire team!",
            rating: 5,
            imgname: "Parasuraman"
        },
        {
            name: "Mr. Dhanasekar",
            project: "Structural Designing",
            title: "Flawless designs!",
            message: "Excellent work! The designs were flawless, and they ensured the structure would be sturdy and reliable.",
            rating: 5,
            imgname: "Dhanasekar"
        },
        {
            name: "KRV Constructions",
            project: "Constructions",
            title: "Outstanding work!",
            message: "The construction team was outstanding! They delivered the project on time with great quality and efficiency.",
            rating: 5,
            imgname: "KRV+Constructions"
        },
        {
            name: "Mrs. Geetha Ajithkumar",
            project: "DTCP Layout",
            title: "Extremely happy!",
            message: "Extremely happy with the layout design. The team made the process smooth and stress-free. Thank you!",
            rating: 5,
            imgname: "Geetha+Ajithkumar"
        },
        {
            name: "Mr. Janarthanan",
            project: "DTCP Layout",
            title: "Professionally handled!",
            message: "The DTCP layout was handled very professionally. The team did a fantastic job!",
            rating: 5,
            imgname: "Janarthanan"
        },
        {
            name: "Mr. Suresh",
            project: "DTCP Layout",
            title: "Exceeded expectations!",
            message: "The layout was perfectly planned and executed. The team exceeded my expectations!",
            rating: 5,
            imgname: "Suresh"
        }
    ];

      


  
  
    const data = {modalRef, renderImages, setRenderImages, renderImg, closeRenderedImg, renderImageIndex, setRenderImageIndex, clientFeedback}


  return (
    <Dcontext.Provider value={data}>
        {props.children}
    </Dcontext.Provider>
  )
}

export default DataContext