import React from 'react'
import { useNavigate } from 'react-router-dom'

const Footer = () => {

    const navigatePage = useNavigate()

  return (
    <div className='bg-primary text-light p-2'>
        <div className='d-flex flex-wrap justify-content-evenly align-items-top mt-3'>
            <div className='my-3' style={{maxWidth: '300px'}}>
                <h2 className='text-light text-center'><i className='bi bi-building'></i> Office</h2>
                <p className='gray-font' style={{textAlign: 'justify'}}>SAN Planners & Designers, No.14/1, 2nd Floor, VSM Complex, Kanjikovil Road, Near Anna Silai, Perundurai, Erode – 638 052.</p>
            </div>
            <div className='my-3' style={{maxWidth: '300px'}}>
                <h2 className='text-light text-center'><i className='bi bi-link-45deg'></i> Quick Links</h2>
                <ul className='gray-font' style={{listStyleType: 'none', textDecoration: 'underline'}}>
                    <li className='fs-6 d-flex gap-2 align-items-center' role='button' onClick={()=>navigatePage('/')}><i className='bi bi-house'></i> Home</li>
                    <li className='fs-6 d-flex gap-2 align-items-center' role='button' onClick={()=>navigatePage('/services')}><i className='bi bi-bezier'></i> Services</li>
                    <li className='fs-6 d-flex gap-2 align-items-center' role='button' onClick={()=>navigatePage('/about')}><i className='bi bi-building'></i> About Us</li>
                    <li className='fs-6 d-flex gap-2 align-items-center' role='button' onClick={()=>navigatePage('/projects')}><i className='bi bi-kanban'></i> Projects</li>
                    <li className='fs-6 d-flex gap-2 align-items-center' role='button' onClick={()=>navigatePage('/contact')}><i className='bi bi-book'></i> Contact Us</li>
                </ul>
            </div>
        </div>
        <div className='fs-1 mx-auto' style={{width: 'fit-content'}}>
            <i className='bi bi-whatsapp px-2 py-1 text-light rounded-circle m-2' role='button' onClick={()=>window.open(`https://wa.me/917305469679?text=Hello%20SAN%20Planners%20%26%20Designers%20team!%20I%E2%80%99m%20interested%20in%20learning%20more%20about%20your%20services.%20Could%20you%20please%20provide%20me%20with%20more%20details%3F%20Thank%20you!`,'_blank')}></i>
            <i className='bi bi-instagram px-2 py-1 text-light rounded-circle m-2' role='button' onClick={()=>window.open('https://www.instagram.com/san_planners?igsh=NmoxYnI0Y2VhOHQ0','_blank')}></i>
            <i className='bi bi-facebook px-2 py-1 text-light rounded-circle m-2' role='button' onClick={()=>window.open('https://www.facebook.com/people/Sanplanners-Buildingdesigner/pfbid02vtP4jk9R4V3w5BUnSNZ5JxqfN5J7npzJNcZuJrmUVkFywsxn9QDe2yXKHkRKkpRGl/?ref=xav_ig_profile_web','_blank')}></i>
            <i className='bi bi-telephone px-2 py-1 text-light rounded-circle m-2' role='button' onClick={()=>window.open('tel:+917305469679','_blank')}></i>
            <i className='bi bi-envelope px-2 py-1 text-light rounded-circle m-2' role='button' onClick={()=>window.open('mailto:sanplannersperundurai@gmail.com','_blank')}></i>
        </div>
        <p className='my-2 m-0 text-center gray-font'><a href="https://santechnovation.com/" className='text-light' style={{textDecoration: 'none'}}>© SAN Technovation</a>, All Rights reserved 2024</p>
    </div>
  )
}

export default Footer