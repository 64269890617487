import React from 'react'
import DImg from '../../../assets/docs.jpg'

const Documentation = () => {
    
    const documentServices = {
        title: "Document Services",
        desc: "SAN Planners & Designers streamline all document-related services for your land and construction projects, ensuring compliance with legal requirements and providing official documentation to support your project goals. Our dedicated team ensures that every document is accurate, complete, and delivered on time.",
        features: [
            "Online EC (Encumbrance Certificate): Easy access to encumbrance certificates for property transactions.",
            "Patta Name Transfer: Efficient transfer of property rights and documentation.",
            "Topographical Sketches: Detailed sketches for planning and regulatory purposes.",
            "SRO Deed Copy: Official copies of deeds for legal and record-keeping needs.",
            "Non-Encumbrance Certificate (NEC): Certification that property is free from any encumbrances."
        ]
    };

  return (
    <div className='text-center'>
        <dl className='mt-5'>
            <dt><h3 className='text-primary fs-1 fw-bolder my-3'>{documentServices.title.toUpperCase()}</h3></dt>
            <dd className='d-flex flex-wrap gap-3 justify-content-center align-items-top'>
                <img className='service-img' src={DImg} alt='DTCP Layouts' />
                <div className='col-11 col-md-10 col-lg-8 col-xl-6'>
                    <p className='fs-5' style={{textAlign: 'justify'}}>{documentServices.desc}</p>
                    <ul className='text-start fs-5' style={{width: 'fit-content'}}>
                        {documentServices.features.map((feature, i)=>{
                            return(
                                <li key={i}>{feature}</li>
                            )
                        })}
                    </ul>
                </div>
            </dd>
        </dl>
    </div>
  )
}

export default Documentation