import React from 'react'
import TestimonialsSliders from '../TestimonialsSliders'

const AboutTestimonials = (props) => {

    const Content = props.testimonials

  return (
    <div className='about-sections'>
        <h3 className="text-primary text-center fw-bolder fs-2">What our Client say?</h3>
        <p className='text-center fs-5'>{Content.desc}</p>
        {/* <div className='d-flex flex-wrap justify-content-center align-items-center'>
        {
            Content.feedback.map((content, i)=>{
            return(
                <div className='service-card m-3' key={i}>
                <div className='p-4'>
                    <h3 className='service-card-title text-primary fw-bolder fs-3'>{content.name}</h3>
                    <p className='my-2'>{content.project}</p>
                    <p className='service-card-desc m-0 fs-5' style={{textAlign: 'justify'}}>{content.desc}</p>
                </div>
                <div className='w-100 service-card-img-layer-container'>
                    <div className='service-card-img-layer1'></div>
                    <div className='service-card-img-layer2'></div>
                    <div className='service-card-img-layer4' style={{background: `url(${content.img})`}}>
                    </div>
                </div>
                </div>
            )
            })
        }
        </div> */}
        <TestimonialsSliders/>
    </div>
  )
}

export default AboutTestimonials