import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import ContainerImg from '../../assets/landing-removebg-large.png'
// import ContainerImg from '../../assets/house model bg.png'
import ServiceBlock from '../blocks/ServiceBlock'
import Testimonials from '../blocks/TestimonialsV2'
import History from '../blocks/History'
import { useNavigate } from 'react-router-dom'
import "../../css/Test.css"

const Home = () => {

    const { t } = useTranslation()

    const navPage = useNavigate()

    // useEffect(() => {
    //     const handleScroll = () => {
    //       const elements = document.querySelectorAll(".landing-container");
    //       const containerImg = document.querySelectorAll(".container-img")
    //       const windowHeight = window.innerHeight;
    
    //       elements.forEach((element) => {
    //         const { top } = element.getBoundingClientRect();
    
    //         // Check if the element is in the viewport
    //         if (top < windowHeight && !containerImg.classList.contains("visible")) {
    //             // if (top < windowHeight && !element.classList.contains("visible")) {
    //             containerImg.classList.add("visible");
    //         }
    //       });
    //     };
    
    //     // Add scroll event listener
    //     window.addEventListener("scroll", handleScroll);
    
    //     // Cleanup the event listener
    //     return () => {
    //       window.removeEventListener("scroll", handleScroll);
    //     };
    //   }, []);


      useEffect(()=>{
        const containerImg = document.getElementsByClassName("container-img")
        const tagLine = document.getElementById("landing-tagline")

        if(containerImg){
            setTimeout(() => {
                containerImg[0].classList.add("visible")
            }, 500);
        }

        if(tagLine){
            tagLine.classList.add("tagline-visible")
        }
      },[])


  return (
    <div>
        <div className='landing-container' style={{minHeight: '80vh'}}>
            <div className='d-flex flex-wrap align-items-center justify-content-center'>
                <div className='mx-3 my-5 col-11 col-md-10 col-lg-6 col-xl-6'>
                    <p className='m-0 fs-5 fw-bold' id='landing-tagline'><em>{t("landing_tagline")}</em></p>
                    <h1 className='mb-4 landing-title text-primary'>SAN <span className='landing-text-secondary'>Planners & Building Designs</span></h1>
                    <p className='p-0 my-4 fs-5 landing-desc'>{t('landing_desc')}</p>
                    <div className='d-flex flex-wrap gap-5 align-items-center'>
                        <button className='landing-btn fs-3 px-4 py-1 rounded-pill' onClick={()=>navPage('/projects')}>{t('landing_cta')}</button>
                        <div>
                            <i className='bi bi-whatsapp landing-icons m-2 rounded-circle' role='button' onClick={()=>window.open('https://wa.me/+917305469679','_blank')}></i>
                            <i className='bi bi-instagram landing-icons m-2 rounded-circle' role='button' onClick={()=>window.open('https://www.instagram.com/san_planners?igsh=NmoxYnI0Y2VhOHQ0','_blank')}></i>
                            <i className='bi bi-facebook landing-icons m-2 rounded-circle' role='button' onClick={()=>window.open('https://www.facebook.com/people/Sanplanners-Buildingdesigner/pfbid02vtP4jk9R4V3w5BUnSNZ5JxqfN5J7npzJNcZuJrmUVkFywsxn9QDe2yXKHkRKkpRGl/?ref=xav_ig_profile_web','_blank')}></i>
                        </div>
                    </div>
                </div>
                <div className='col-11 col-md-10 col-lg-6 col-xl-5'>
                    <img className='container-img rounded' src={ContainerImg} alt='SAN Planners & Designers'/>
                </div>
            </div>
            <div className='text-light landing-footer-container'>
                <div className='d-flex align-items-center my-2'>
                    <i className='bi bi-geo-alt-fill fs-5 landing-footer-icon'></i>
                    <p className='m-0'> <b className='text'>{t('address_title')}: </b>{t('address')}</p>
                </div>
                <div className='d-flex align-items-center my-2'>
                    <i className='bi bi-telephone-fill fs-5 landing-footer-icon'></i>
                    <p className='m-0'> <b className='text'>{t('contact_title')}: </b><span className='fw-bold' role='button' onClick={()=>window.location.href="tel:+917305469679"}>+91 73054 69679</span> | <span role='button' onClick={()=>window.location.href="tel:+918072540319"}> 80725 40319</span> | <span role='button' onClick={()=>window.location.href="tel:+916382269679"}>63822 69679</span></p>
                </div>
            </div>
            
        </div>
        <History/>
        <ServiceBlock/>
        {/* <ProjectsBlock/> */}
        <Testimonials/>




    </div>
  )
}

export default Home