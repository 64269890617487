import React from 'react'
import BImg from '../../../assets/construction.jpg'

const FMB = () => {
    
    const fmbDetailedServices = {
        title: "FMB Services",
        desc: "SAN Planners & Designers offer precise and reliable FMB (Field Measurement Book) services that document land details, ensuring accurate records for legal, civil, and construction processes. We prioritize accuracy and attention to detail to provide you with the best documentation for your project.",
        features: [
            "Land Subdivision Services: Expert assistance with subdividing land for various uses.",
            "Surveying & Mapping: Comprehensive surveying services to capture all land features.",
            "FMB Document Preparation: Professional preparation of field measurement books for legal compliance.",
            "Legal Record Keeping: Ensuring all records are maintained according to legal standards.",
            "Customized Measurement Reports: Tailored reports to suit your specific project needs."
        ]
    };

  return (
    <div className='text-center'>
        <dl className='mt-5'>
            <dt><h3 className='text-primary fs-1 fw-bolder my-3'>{fmbDetailedServices.title.toUpperCase()}</h3></dt>
            <dd className='d-flex flex-wrap gap-3 justify-content-center align-items-top'>
                <img className='service-img' src={BImg} alt='Blueprint' />
                <div className='col-11 col-md-10 col-lg-8 col-xl-6'>
                    <p className='fs-5' style={{textAlign: 'justify'}}>{fmbDetailedServices.desc}</p>
                    <ul className='text-start fs-5' style={{width: 'fit-content'}}>
                        {fmbDetailedServices.features.map((feature, i)=>{
                            return(
                                <li key={i}>{feature}</li>
                            )
                        })}
                    </ul>
                </div>
            </dd>
        </dl>
    </div>
  )
}

export default FMB