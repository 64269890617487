import React from 'react'
import Svg from '../../assets/404.svg'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {

  const navigatePage = useNavigate()

  return (
    <div className='text-center'>
        <img className='my-5 col-10 col-md-8 col-lg-6 col-xl-4' src={Svg} alt='Not Found' />
        <h2>NOT FOUND!</h2>
        <p>The page you are searching for is not found.</p>
        <button className='btn btn-primary' onClick={()=>navigatePage('/')}>Goto Homepage</button>
    </div>
  )
}

export default NotFound