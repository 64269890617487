import React from 'react'
import DImg from '../../../assets/team2.jpg'

const Estimation = () => {
    
    const estimatingCosting = {
        title: "Estimating & Costing",
        desc: "Our estimating and costing services provide an accurate financial overview for your construction project, helping you plan your budget effectively and avoid unforeseen expenses. With detailed analyses and transparent reporting, we ensure you have all the information needed to make informed decisions.",
        features: [
            "Building Valuation: Accurate valuations for various types of properties.",
            "Bank Estimations: Support for obtaining financing with accurate cost estimates.",
            "Cost Analysis for Construction Projects: Detailed analysis to identify potential cost savings.",
            "Budget Planning Assistance: Help you plan and manage budgets throughout the project lifecycle.",
            "Transparent Reporting: Clear and concise reports that keep you informed of all financial aspects."
        ]
    };

  return (
    <div className='text-center'>
        <dl className='mt-5'>
            <dt><h3 className='text-primary fs-1 fw-bolder my-3'>{estimatingCosting.title.toUpperCase()}</h3></dt>
            <dd className='d-flex flex-wrap gap-3 justify-content-center align-items-top'>
                <img className='service-img' src={DImg} alt='DTCP Layouts' />
                <div className='col-11 col-md-10 col-lg-8 col-xl-6'>
                    <p className='fs-5' style={{textAlign: 'justify'}}>{estimatingCosting.desc}</p>
                    <ul className='text-start fs-5' style={{width: 'fit-content'}}>
                        {estimatingCosting.features.map((feature, i)=>{
                            return(
                                <li key={i}>{feature}</li>
                            )
                        })}
                    </ul>
                </div>
            </dd>
        </dl>
    </div>
  )
}

export default Estimation