import React from 'react'
import DImg from '../../../assets/designing.jpg'

const StructuralDesign = () => {
    
    const structuralDesign = {
        title: "Structural Design",
        desc: "At SAN Planners & Designers, we provide comprehensive structural design services that ensure the stability and integrity of your building. Whether for residential, commercial, or industrial purposes, our expert team ensures that the foundation and framework are designed to meet industry standards and client requirements. Our approach combines innovative design with practical engineering solutions to deliver robust structures.",
        features: [
            "Structural Integrity Assessment: Comprehensive assessments to ensure your building's safety.",
            "3D Structural Modeling: Advanced modeling techniques for accurate structural representation.",
            "Load Calculations: Precise calculations to ensure structures can handle expected loads.",
            "Foundation & Beam Design: Expertise in designing foundations and beams to support various structures.",
            "Compliance with Regulatory Standards: Assurance that all designs meet local and national building codes."
        ]
    };

  return (
    <div className='text-center'>
        <dl className='mt-5'>
            <dt><h3 className='text-primary fs-1 fw-bolder my-3'>{structuralDesign.title.toUpperCase()}</h3></dt>
            <dd className='d-flex flex-wrap gap-3 justify-content-center align-items-top'>
                <img className='service-img' src={DImg} alt='DTCP Layouts' />
                <div className='col-11 col-md-10 col-lg-8 col-xl-6'>
                    <p className='fs-5' style={{textAlign: 'justify'}}>{structuralDesign.desc}</p>
                    <ul className='text-start fs-5' style={{width: 'fit-content'}}>
                        {structuralDesign.features.map((feature, i)=>{
                            return(
                                <li key={i}>{feature}</li>
                            )
                        })}
                    </ul>
                </div>
            </dd>
        </dl>
    </div>
  )
}

export default StructuralDesign