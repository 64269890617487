import React, { useEffect, useState } from 'react'

const ProjectCounts = () => {

    const [expCount, setExpCount] = useState(0)
    const [prjCount, setPrjCount] = useState(0)
    const [cliCount, setCliCount] = useState(0)
    // const [brCount, setBrCount] = useState(0)

    const countProgress = (title, count, currentCount = 0) => {
        if(currentCount <= count){
            setTimeout(()=>{
                if(title==='Experience'){
                    setExpCount(currentCount)
                }
                else if(title==='Projects'){
                    setPrjCount(currentCount)
                }
                else if(title==='Clients'){
                    setCliCount(currentCount)
                }
                // else if(title==='Branches'){
                //     setBrCount(currentCount)
                // }
                countProgress(title, count, currentCount + 1)
            },20)
        }
    }

    const Counts = [
        {
            title: 'Experience',
            count: 1,
            progess: expCount,
            icon: 'suitcase-lg'
        },
        {
            title: 'Projects',
            count: 200,
            progess: prjCount,
            icon: 'boxes'
        },
        {
            title: 'Clients',
            count: 60,
            progess: cliCount,
            icon: 'people'
        },
        // {
        //     title: 'Branches',
        //     count: 3,
        //     progess: brCount,
        //     icon: 'diagram-2'
        // }
    ]

    useEffect(()=>{
        Counts.forEach(item => {
            countProgress(item.title, item.count)   
        });
    },[])

  return (
    <div className='w-100'>
        <div className='count-card-container col-11 col-md-10 col-lg-8 col-xl-8 mx-auto d-flex flex-wrap justify-content-center align-items-center'>
            {
                Counts.map((item, i)=>{
                    return(
                        <div className='count-card col-8 col-md-6 col-lg-4 col-xl-3 m-2 p-4 text-center d-flex justify-content-center align-items-center' key={i}>
                            <div>
                                <i className={`bi bi-${item.icon} d-block text-primary display-3`}></i>
                                <span className='fs-1 text-dark fw-bolder'>{`${item.title==='Experience'?item.progess:item.progess+'+'}`}</span>
                                <h4 className='fs-3'>{item.title.toUpperCase()}</h4>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    </div>
  )
}

export default ProjectCounts