import React from 'react'
import { useNavigate } from 'react-router-dom'

const AboutHistory = (props) => {

    const navi = useNavigate()
    const Content = props.history

  return (
    <div className='about-sections about-history-container py-5 d-flex flex-wrap gap-3 justify-content-center align-items-center'>
        <div className=' col-11 col-md-10 col-lg-5 col-xl-4'>
            <h3 className="text-primary text-start my-3 fw-bolder about-history-desc">History of our Service</h3>
        </div>
        <div className='col-11 col-md-10 col-lg-6 col-xl-5'>
            <p className='fs-5' style={{textAlign: 'justify'}}>{Content[0]}</p>
            <p className='fs-5' style={{textAlign: 'justify'}}>{Content[1]}</p>
            <button onClick={()=>navi('/services')} className='btn btn-outline-primary fs-4 px-4 rounded-pill'>Our Services</button>
        </div>
    </div>
  )
}

export default AboutHistory