import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'

const Contact = () => {

  const formRef = useRef('')
  const nameRef = useRef('')
  const emailRef = useRef('')
  const contactRef = useRef('')
  const cityRef = useRef('')
  const villageRef = useRef('')
  const selectRef = useRef('')
  const messageRef = useRef('')

  const [mailStatus, setMailStatus] = useState(false)

  const servcieID = process.env.REACT_APP_SANServiceID
  const templateID = process.env.REACT_APP_SANTemplateID
  const Api = process.env.REACT_APP_SANAPI
  

  const handleSubmission = (e) => {



    setMailStatus(true)

    e.preventDefault()

    formRef.current.classList.add("was-validated")

    if(nameRef.current.value!==''&&contactRef.current.value!==''&&cityRef.current.value!==''&&selectRef.current.value!==''){
      const enquiry = {name: nameRef.current.value, email: emailRef.current.value, contact: contactRef.current.value, city: cityRef.current.value, village: villageRef.current.value, option: selectRef.current.value, message: messageRef.current.value}
      emailjs
        .send(servcieID, templateID, {enquiry}, Api)
        .then(() => {
          alert('Enquiry sent successfully!')
          setMailStatus(false)
          formRef.current.classList.remove("was-validated")
          formRef.current.reset()
        })
        .catch((error) => {
          setMailStatus(false)
          console.log('FAILED to sent Enquiry: ', error);
          alert('Failed to sent Enquiry, please try again later or contact Admin.')
        })
    }
    else{
      alert("Please Enter the required details!")
      setMailStatus(false)
    }

    
  }


  return (
    <div className='w-100'>
      <h2 className='text-primary fs-1 fw-bolder mt-5 text-center'>Contact Us</h2>
      <p className='fs-5 col-11 col-md-10 col-lg-8 col-xl-6 mx-auto' style={{textAlign: 'justify'}}>We’d love to hear from you! Whether you have a question about our services, need assistance, or want to schedule a consultation, our team is ready to help.</p>
      <div className='d-flex flex-wrap justify-content-center align-items-center'>
        <div className='contact-card-container d-flex flex-wrap col-11 col-md-10 col-lg-10 col-xl-8 my-3'>
          <div className='col-12 col-md-12 col-lg-6 col-xl-6 bg-primary contact-card' style={{color: 'white'}}>
            <div className='w-100'>
              <h3 className='mt-2 fw-bold text-center fs-2'><u>Get In Touch</u></h3>
              <p className='fs-normal gray-font' style={{textAlign: 'justify'}}>Feel free to reach out to us through any of the following methods. We’ll get back to you as soon as possible.</p>
              <ul className='fs-normal' style={{listStyleType: 'none'}}>
                <li className='my-3 gray-font'><b className='text-light'><i className='bi bi-telephone bg-light text-dark px-2 py-1 fs-5 rounded-circle'></i> Phone:</b> <span role='button' className='fw-bold text-light' onClick={()=>window.location.href="tel:+917305469679"}>+91 73054 69679</span> | <span role='button' onClick={()=>window.location.href="tel:+918072540319"}> 80725 40319</span> | <span role='button' onClick={()=>window.location.href="tel:+916382269679"}>63822 69679</span></li>
                <li className='my-3 gray-font text-break' style={{boxSizing: 'border-box'}} onClick={()=>window.location.href="mailto:sanplannersperundurai@gmail.com"} role='button'><b className='text-light'><i className='bi bi-envelope bg-light text-dark px-2 py-1 fs-5 rounded-circle'></i> Email:</b> <p className='text d-inline'>sanplannersperundurai@gmail.com</p></li>
                <li className='my-3 gray-font' style={{textAlign: 'justify'}}><b className='text-light'><i className='bi bi-geo bg-light text-dark px-2 py-1 fs-5 rounded-circle'></i> Address:</b> SAN Planners & Designers, No.14/1, 2nd Floor, VSM Complex, Kanjikovil Road, Near Anna Silai, Perundurai, Erode – 638 052.</li>
              </ul>
            </div>
            <div>
              <h3 className='mt-4 fw-bold text-center fs-2'><u>Our Office Hours</u></h3>
              <ul className='fs-normal'>
                <li className='fw-bold'>Enquiry: 24/7 Available</li>
                <li className=' gray-font'>Monday to Saturday: 9:30 AM – 6:30 PM</li>
                <li className=' gray-font'>Sunday: Closed</li>
              </ul>
            </div>
            <div>
              <h3 className='mt-4 fw-bold text-center fs-2'><u>Find Us on the Map</u></h3>
              <iframe title='SAN Location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d303.4549607007363!2d77.58468699240515!3d11.27948553680099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba96d880618826d%3A0x7b06a1c794d65962!2sSAN%20TECHNOVATION!5e1!3m2!1sen!2sin!4v1728883631596!5m2!1sen!2sin" height="200"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{width: "100%"}}></iframe>
            </div>
          </div>
          <form ref={formRef} className='contact-form-card needs-validation col-12 col-md-12 col-lg-6 col-xl-6' noValidate onSubmit={(e)=>{handleSubmission(e)}}>
            <p className='fs-4 fw-bold text-primary'>Fill out the form below, and we’ll get in touch with you</p>
            <div className="mb-3">
              <label htmlFor="Input1" className="form-label fs-5" name="fullname">Full Name</label>
              <input ref={nameRef} type="text" className="form-control" id="Input1" placeholder="Enter your Name" required/>
              <div className="invalid-feedback">
                Please enter your Fullname.
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="Input3" className="form-label fs-5" name="contact">Contact Number</label>
              <input ref={contactRef} type="tel" className="form-control" id="Input3" placeholder="98765 43210" required/>
              <div className="invalid-feedback">
              Please enter your contact Number.
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="Input2" className="form-label fs-5" name="email">Email address</label>
              <input ref={emailRef} type="email" className="form-control" id="Input2" placeholder="yourname@mail.com"/>
            </div>
            <div className="mb-3">
              <label htmlFor="Input4" className="form-label fs-5" name="city">City</label>
              <input ref={cityRef} type="text" className="form-control" id="Input4" placeholder="Eg: Perundurai" required/>
              <div className="invalid-feedback">
                Please enter your City.
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="Input5" className="form-label fs-5" name="village">Village</label>
              <input ref={villageRef} type="text" className="form-control" id="Input5" placeholder="Your Village"/>
            </div>
            <div className='mb-3'>
              <label htmlFor='SelectOption' className="form-label fs-5" onClick={()=>console.log(selectRef.current.value)}>Select Category</label>
              <select ref={selectRef} className='form-select' id='SelectOption' defaultValue='' required>
                <option value='' disabled>Choose a category</option>
                <option value='Blueprint Approvals'>Blueprint Approvals</option>
                <option value="DTCP Layouts & Approvals">DTCP Layouts & Approvals</option>
                <option value="3D & 2D Designs">3D & 2D Designs</option>
                <option value="Structural Designs">Structural Designs</option>
                <option value="Construction">Construction</option>
                <option value="Estimation & Costing">Estimation & Costing</option>
                <option value="Document">Document</option>
                <option value="FMB (Sub Division)">FMB (Sub Division)</option>
              </select>
              <div className="invalid-feedback">
                Please choose a category.
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="Textarea1" className="form-label fs-5" name="message">Message</label>
              <textarea ref={messageRef} className="form-control" id="Textarea1" rows="3" placeholder='Your message'></textarea>
            </div>
            <div className='d-flex gap-3 align-items-center'>
              {!mailStatus?
                <button type='submit' className='btn btn-primary px-4 fs-5 rounded-pill m-2'>Submit</button>: 
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              }
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact