import React, {useEffect} from 'react'
import Team1 from '../../assets/team1.jpg'
import Team2 from '../../assets/team2.jpg'
import { useNavigate } from 'react-router-dom'

const History = () => {

  const navPage = useNavigate()


      useEffect(() => {
        const handleScroll = () => {
          const elements = document.querySelectorAll(".home-about-container");
          const slideImgA = document.getElementById("home-about-img-a")
          const slideImgB = document.getElementById("home-about-img-b")
          const windowHeight = window.innerHeight;
    
          elements.forEach((element) => {
            const { top } = element.getBoundingClientRect();
    
            // Check if the element is in the viewport
            if (top < windowHeight){
              if(!slideImgA.classList.contains("visible")) {
                slideImgA.classList.add("visible");
              }
              if(!slideImgB.classList.contains("visible")) {
                slideImgB.classList.add("visible");
              }
            }
          });
        };
    
        // Add scroll event listener
        window.addEventListener("scroll", handleScroll);
    
        // Cleanup the event listener
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);


  return (
    <div className='pt-5 d-flex flex-wrap gap-5 justify-content-center align-items-center'>
        <div className='home-about-container'>
            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                <div className='w-75 h-75 bg-light home-about-container-inner'></div>
            </div>
            <img id="home-about-img-a" className=' home-about-img home-about-img-a' src={Team1} alt='Our Team A' />
            <img id="home-about-img-b" className=' home-about-img home-about-img-b' src={Team2} alt='Our Team B' />
        </div>
        <div className='col-11 col-md-10 col-lg-6 col-xl-5'>
            <h2 className='fs-1 text-primary my-3' style={{fontWeight: '900'}}>About Us</h2>
            <p className='fw-bold fs-4'>Crafting Dreams into Reality with Precision and Innovation</p>
            <p className='fs-5' style={{textAlign: 'justify'}}>At SAN Planners & Designers, we are dedicated to providing exceptional planning, designing and construction services. With a commitment to quality and creativity, we transform your vision into functional and aesthetically pleasing spaces. From DTCP layouts to architectural designs, we bring expertise and passion to every project, ensuring seamless execution and customer satisfaction.</p>
            <button className='btn btn-secondary fs-4 px-4 rounded-pill' onClick={()=>navPage('/about')}>Read more</button>
            <div className='my-3 p-2 d-flex flex-wrap justify-content-center'>
                
            </div>
        </div>
    </div>
  )
}

export default History