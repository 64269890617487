import React, { useState } from 'react'
import LogoIcon from '../../assets/SAN Planners Icon new.png'
import LogoText from '../../assets/SAN Planners Logo text new.png'
import { useNavigate } from 'react-router-dom'

const Header = (props) => {

    const navPage = useNavigate()

    const [renderingMenu, setRenderingMenu] = useState(null)

    
    const menuItems = [
      {
        title: 'Home',
        path: '/',
        icon: 'home',
      },
      {
        title: 'About Us',
        path: '/about',
        icon: 'source_environment',
      },
      {
        title: 'Services',
        subMenu: [
          {
            title: 'Blueprint Approvals',
            subMenu: [
              { title: 'Building Plan Approval', path: '/blueprint-approvals' },
              { title: 'Online Blueprint', path: '/blueprint-approvals' },
              { title: 'Building & Industry Layout', path: '/blueprint-approvals' },
            ],
            path: '/blueprint-approvals'
          },
          {
            title: 'DTCP Layouts & Approvals',
            subMenu: [
              { title: 'DTCP Layout Plan Approval', path: '/dtcp-layout' },
              { title: 'DTCP Unapproved Layout Plan Approval', path: '/dtcp-layout' },
            ],
            path: "/dtcp-layout"
          },
          {
            title: 'Designs',
            subMenu: [
              {
                title: '3D Design',
                subMenu: [
                  { title: 'Architectural Design', path: '/design-3d' },
                  { title: '3D Elevation Design & Walkthrough', path: '/design-3d' },
                  { title: 'Interior', path: '/design-3d' },
                  { title: 'Exterior', path: '/design-3d' },
                ],
                path: "/design-3d"
              },
              {
                title: '2D Design',
                subMenu: [
                  { title: 'Vastu Plan', path: '/design-2d' },
                  { title: 'Plumbing and Electrical Design', path: '/design-2d' },
                ],
                path: "/design-2d"
              },
            ],
            path: '/design'
          },
          { 
            title: 'Structural Design', path: "/structural-design",
            subMenu: [
              { title: 'Residential/ Commercial Structures', path: '/structural-design' },
              { title: 'Industrial/ Steel Structures', path: '/structural-design' },
              { title: 'Reinforced Concrete Structures', path: '/structural-design' },
              { title: 'Green Certification Building Structures', path: '/structural-design' }
            ],            
          },
          {
            title: 'Construction',
            subMenu: [
              { title: 'Project Management', path: '/construction' },
              { title: 'Construction Consulting', path: '/construction' },
              { title: 'Consultations', path: '/construction' },
            ],
            path: "/construction"
          },
          {
            title: 'Estimating & Costing',
            subMenu: [
              { title: 'Building Valuation', path: '/estimation' },
              { title: 'Bank Estimations', path: '/estimation' },
            ],
            path: "/estimation"
          },
          {
            title: 'Document',
            subMenu: [
              { title: 'Online EC, SRO EC (Encumbrance Certificate)', path: '/documentation' },
              { title: 'Patta Name Transfer', path: '/documentation' },
              { title: 'SRO Deed Copy', path: '/documentation' },
              { title: 'Non-Encumbrance Certificate (NEC)', path: '/documentation' },
            ],
            path: "/documentation"
          },
          {
            title: 'FMB (Sub Division)',
            subMenu: [
              { title: 'FMB Drafting', path: '/fmb' },
              { title: 'Topo Sketch (Topographical Sketch)', path: '/fmb' },
              { title: 'Land Subdivision', path: '/fmb' },
              { title: 'Land Surveying', path: '/fmb' },
              { title: 'Surveyor Map - FMB (Field Measurement Book)', path: '/fmb' },
              { title: 'Survey Plan', path: '/fmb' },
            ],
            path: "/fmb"
          },
        ],
        icon: 'linked_services',
        path: '/services'
      },
      {
        title: 'Projects',
        path: '/projects',
        icon: 'view_comfy_alt',
        subMenu: [
          {
            title: 'Blueprint',
            path: '/project-blueprint'
          },
          {
            title: 'DTCP',
            path: '/project-dtcp'
          },
          {
            title: 'Interior Designing',
            path: '/project-interior'
          },
          {
            title: 'Exterior Designing',
            path: '/project-exterior'
          },
          {
            title: '2D Design',
            path: '/project-2d'
          },
          {
            title: 'FMB (Sub Division)',
            path: '/project-fmb'
          }
        ]
      },
      {
        title: 'Service Zones',
        subMenu: [
          {
            title: 'Corporation (1+)',
            path: '/service-zones',
          },
          {
            title: 'Municipality (3+)',
            path: '/service-zones',
          },
          {
            title: 'DTCP - District Offices (5+)',
            path: '/service-zones',
          },
          {
            title: 'Taluka (7+)',
            path: '/service-zones',
          },
          {
            title: 'Union Offices (6+)',
            path: '/service-zones',
          },
          {
            title: 'Sub-Registrar Office (14+)',
            path: '/service-zones',
          },
          {
            title: 'Town Panchayat (14+)',
            path: '/service-zones',
          },
          {
            title: 'Villages (143+)',
            path: '/service-zones',
          }
        ],
        path: '/service-zones'
      },
      {
        title: 'Internship',
        path: '/internship',
        icon: 'work',
      },
      {
        title: 'Contact Us',
        path: '/contact',
        icon: 'contacts_product',
      },
  ];


  const renderMenu = (menuItem) => {
    if(renderingMenu===menuItem.title){
      return(
        <div className='h-trend-menulist-categories'>
          <div className='mx-auto bg-light border p-5 d-flex' onMouseOver={()=>setRenderingMenu(menuItem.title)} onMouseOut={()=>setRenderingMenu(null)} >
            <div className='m-2' style={{width: 'max-content'}}>
              {
                menuItem.subMenu.map((subSubMenu, subSubmenuI)=>{
                  if(subSubmenuI<3){
                    return(
                      <dl key={subSubmenuI}>
                        <dt className='menu-items text-danger' role='button' onClick={()=>{navPage(subSubMenu.path);setRenderingMenu(null)}}>{subSubMenu.title}</dt>
                        {
                          subSubMenu.subMenu&&
                          <dd>
                            <ul className='h-trend-menulist-ul'>
                              {
                                subSubMenu.subMenu.map((childSubMenu, childSubMenuI)=>{
                                  return(
                                    <li key={childSubMenuI}>
                                      <span className={`sub-menu-items ${childSubMenu.subMenu?'fw-bold':''}`} role='button' onClick={()=>{navPage(childSubMenu.path);setRenderingMenu(null)}}>{childSubMenu.title}</span>
                                      {
                                        childSubMenu.subMenu&&
                                        <ul className='h-trend-sub-menulist-ul'>
                                          {
                                            childSubMenu.subMenu.map((childSubSubMenu, childSubSubMenuI)=>{
                                              return(
                                                <li className='child-submenu-items' key={childSubSubMenuI} role='button' onClick={()=>{navPage(childSubSubMenu.path);setRenderingMenu(null)}}>{childSubSubMenu.title}</li>
                                              )
                                            })
                                          }
                                        </ul>
                                      }
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </dd>
                        }
                      </dl>
                    )
                  }
                  else{
                    return null
                  }
                })
              }
            </div>
            <div className='m-2' style={{width: 'max-content'}}>
            {
                menuItem.subMenu.map((subSubMenu, subSubmenuI)=>{
                  if(subSubmenuI>2&&subSubmenuI<6){
                    return(
                      <dl key={subSubmenuI}>
                        <dt className='menu-items text-danger' role='button' onClick={()=>{navPage(subSubMenu.path);setRenderingMenu(null)}}>{subSubMenu.title}</dt>
                        {
                          subSubMenu.subMenu&&
                          <dd>
                            <ul className='h-trend-menulist-ul'>
                              {
                                subSubMenu.subMenu.map((childSubMenu, childSubMenuI)=>{
                                  return(
                                    <li key={childSubMenuI}>
                                      <span className='sub-menu-items' role='button' onClick={()=>{navPage(childSubMenu.path);setRenderingMenu(null)}}>{childSubMenu.title}</span>
                                      {
                                        childSubMenu.subMenu&&
                                        <ul className='h-trend-sub-menulist-ul'>
                                          {
                                            childSubMenu.subMenu.map((childSubSubMenu, childSubSubMenuI)=>{
                                              return(
                                                <li className='child-submenu-items' key={childSubSubMenuI} role='button' onClick={()=>{navPage(childSubSubMenu.path);setRenderingMenu(null)}}>{childSubSubMenu.title}</li>
                                              )
                                            })
                                          }
                                        </ul>
                                      }
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </dd>
                        }
                      </dl>
                    )
                  }
                  else{
                    return null
                  }
                })
              }
            </div>
            <div className='m-2' style={{width: 'max-content'}}>
            {
                menuItem.subMenu.map((subSubMenu, subSubmenuI)=>{
                  if(subSubmenuI>5&&subSubmenuI<9){
                    return(
                      <dl key={subSubmenuI}>
                        <dt className='menu-items text-danger' role='button' onClick={()=>{navPage(subSubMenu.path);setRenderingMenu(null)}}>{subSubMenu.title}</dt>
                        {
                          subSubMenu.subMenu&&
                          <dd>
                            <ul className='h-trend-menulist-ul'>
                              {
                                subSubMenu.subMenu.map((childSubMenu, childSubMenuI)=>{
                                  return(
                                    <li key={childSubMenuI}>
                                      <span className='sub-menu-items' role='button' onClick={()=>{navPage(childSubMenu.path);setRenderingMenu(null)}}>{childSubMenu.title}</span>
                                      {
                                        childSubMenu.subMenu&&
                                        <ul className='h-trend-sub-menulist-ul'>
                                          {
                                            childSubMenu.subMenu.map((childSubSubMenu, childSubSubMenuI)=>{
                                              return(
                                                <li className='child-submenu-items' key={childSubSubMenuI} role='button' onClick={()=>{navPage(childSubSubMenu.path);setRenderingMenu(null)}}>{childSubSubMenu.title}</li>
                                              )
                                            })
                                          }
                                        </ul>
                                      }
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </dd>
                        }
                      </dl>
                    )
                  }
                  else{
                    return null
                  }
                })
              }
            </div>
          </div>
        </div>  
      )
    }
    else
    return null



  }



  return (
    <nav className="p-2 navbar navbar-expand-lg bg-body-tertiary d-flex justify-content-between align-items-center">
        <div className='header-side-blocks'>
            <div className='text-center logo-container' style={{width: 'fit-content'}} onClick={()=>navPage('/')}>
                <img className='logo-icon' src={LogoIcon} alt='SAN Icon' role='button' />
                <img className='logo-text' src={LogoText} alt='SAN Logo' role='button' />
            </div>
        </div>

        <ul className='h-trend-menulist'>
          {
            menuItems.map((menuItem, i)=>{
              return(
                <li className='p-2 mx-1 h-trend-menulist-item' key={i}>
                  {
                    menuItem.subMenu
                    ?<span onMouseOver={()=>setRenderingMenu(menuItem.title)} onMouseOut={()=>setRenderingMenu(null)} onClick={()=>navPage(menuItem.path)} role='button' className='header-menu-items d-flex  align-items-center justify-content-center ' style={{width: 'max-content'}}><span>{menuItem.title}</span><i className='ms-1 mt-1 bi bi-caret-down-fill' style={{fontSize: '14px'}}></i></span>
                    :<span onMouseOver={()=>setRenderingMenu(menuItem.title)} onMouseOut={()=>setRenderingMenu(null)} onClick={()=>navPage(menuItem.path)} role='button' className='header-menu-items ' style={{width: 'max-content'}}>{menuItem.title}</span>
                  }
                  {
                    (menuItem.subMenu)&&renderMenu(menuItem)
                                    
                  }
                </li>
              )
            })
          }
        </ul>
        

        <div className='header-side-blocks text-end'>
            <button className='btn btn-primary rounded-pill header-cta fs-5 px-4' onClick={()=>navPage('/contact')}>Contact Us</button>
        <span className="material-symbols-outlined header-ham display-1 fw-bold border border-4 text-primary p-0 px-1 rounded" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" onClick={()=>props.setMountAccordion(true)}>
        menu
        </span>
        </div>
    </nav>
  )
}

export default Header