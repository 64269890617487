import React, { useContext } from 'react'
import Img1 from '../../../assets/gallery1.jpg'
import Img2 from '../../../assets/gallery2.jpg'
import Img3 from '../../../assets/gallery3.jpg'
import Img4 from '../../../assets/gallery4.jpg'
import Img5 from '../../../assets/gallery5.jpg'
import Img6 from '../../../assets/gallery6.jpg'
import Img7 from '../../../assets/gallery7.jpg'
import Img8 from '../../../assets/residential.jpg'
import Img9 from '../../../assets/gallery8.jpg'
import { Dcontext } from '../../../context/DataContext'

const WovenImageList = (props) => {

  const {renderImg} = useContext(Dcontext)

    let itemData;

    if(props.Images){
      itemData=props.Images
    }
    else{
      itemData = [
        {
            img: Img1,
            title: 'Image 1',
        },
        {
            img: Img2,
            title: 'Image 2',
        },
        {
            img: Img3,
            title: 'Image 3',
        },
        {
            img: Img4,
            title: 'Image 4',
        },
        {
            img: Img5,
            title: 'Image 5',
        },
        {
            img: Img6,
            title: 'Image 6',
        },
        {
            img: Img7,
            title: 'Image 7',
        },
        {
            img: Img8,
            title: 'Image 8',
        },
        {
            img: Img9,
            title: 'Image 9',
        },
        // Add more items as needed
      ];
    }

      

  return (
    <div className='d-flex gap-2 flex-wrap justify-content-center py-3 m-2 rounded rounded-5' style={{background: '#b9e5fe'}}>
      {
        itemData.map((imgData, i)=>{
          return(
            <div className='col-11 col-md-4 col-lg-3 col-xl-2' key={i}>
              <img onClick={()=>renderImg(itemData, i)} className={`col-12 object-fit-cover gallery-img ${i%2===0?`gallery-img-l`:`gallery-img-r`}`} src={imgData.img} alt={imgData.img} loading='lazy' role='button' style={{height: '250px'}} />
            </div>
          )
        })
      }


      



    </div>
  )
}

export default WovenImageList