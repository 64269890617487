import React from 'react'
import BImg from '../../../assets/blueprint.jpg'

const Blueprint = () => {
    
    const blueprintApprovals = {
        title: "Blueprint Approvals",
        desc: "At SAN Planners & Designers, we simplify the complex process of obtaining document approvals and preparing blueprints for your land's regulatory compliance. Our online blueprint services ensure that your project’s architectural and design plans meet all legal requirements, allowing you to navigate building approvals, zoning regulations, and related documentation with ease. We pride ourselves on our thorough understanding of local regulations and the nuances of the approval process, making it easier for our clients to focus on their vision.",
        features: [
            "Government Blueprint Approvals: Navigate the complexities of government regulations with our expert guidance.",
            "Building Plan Approvals: Ensure your construction plans are compliant with local building codes.",
            "Topographical Sketches (Toposketches): Detailed sketches to represent the physical features of your land.",
            "Digital Submission for Regulatory Approvals: Streamlined online processes for faster approval.",
            "Patta and Online EC (Encumbrance Certificate): Facilitate smooth property transactions and legal compliance."
        ]
    };

  return (
    <div className='text-center'>
        <dl className='mt-5'>
            <dt><h3 className='text-primary fs-1 fw-bolder my-3'>{blueprintApprovals.title.toUpperCase()}</h3></dt>
            <dd className='d-flex flex-wrap gap-3 justify-content-center align-items-top'>
                <img className='service-img' src={BImg} alt='Blueprint' />
                <div className='col-11 col-md-10 col-lg-8 col-xl-6'>
                    <p className='fs-5' style={{textAlign: 'justify'}}>{blueprintApprovals.desc}</p>
                    <ul className='text-start fs-5' style={{width: 'fit-content'}}>
                        {blueprintApprovals.features.map((feature, i)=>{
                            return(
                                <li key={i}>{feature}</li>
                            )
                        })}
                    </ul>
                </div>
            </dd>
        </dl>
    </div>
  )
}

export default Blueprint