import React from 'react'
// import Logo from '../../assets/SAN Planners Logo cropped.png'
import Logo from '../../assets/corporate-department.jpg'
import ResidentialImg from '../../assets/residential.jpg'
import CommercialImg from '../../assets/commercial.jpg'
import AboutOffers from '../blocks/about/ProjectCounts'
import AboutHistory from '../blocks/about/AboutHistory'
import AboutChoose from '../blocks/about/AboutChoose'
import AboutTestimonials from '../blocks/about/AboutTestimonials'
import { useNavigate } from 'react-router-dom'

const About = () => {

  const navi = useNavigate()

  const Content = {
    about: [
      'Welcome to SAN Planners & Designers, where your vision comes to life through innovation, precision, and creativity. With a deep commitment to delivering high-quality architectural and design solutions, we ensure that every project we undertake is a testament to our expertise, professionalism, and passion for excellence.',
      `Our agency takes pride in being a trusted partner in the field of architectural planning, structural design, and civil engineering services. Whether you're seeking blueprint approvals, 3D designs, or detailed development plans, we bring a unique approach to every project, ensuring that your space not only meets but exceeds your expectations.`
    ],
    story: [
      `Founded with the goal of simplifying the architectural and construction process, SAN Planners & Designers has grown into a leading agency known for its innovative solutions and client-centered approach. Over the years, we’ve worked with a wide range of clients, from individuals to businesses, offering customized services to meet their specific needs. Our team of professionals brings together expertise from different disciplines, making us a one-stop solution for all your planning and design needs.`,
      `From the moment we started, our focus has been on providing top-tier services that ensure client satisfaction. With years of experience under our belt, we've refined our processes and adapted to modern technologies, always staying ahead of the curve in the architectural world. Our portfolio showcases our ability to handle diverse and complex projects while maintaining high standards.`
    ],
    whyChoose: {
      desc: `At SAN Planners & Designers, we believe in creating value through quality and innovation. Here’s why we stand out:`,
      features: [
        {
          title: "Expertise Across Categories:",
          desc: "Whether it’s Blueprint Approvals, 3D Design, DTCP Layouts, Structural designs, or FMB services, we cover all essential categories to guide your project to success."
        },
        {
          title: "Client-Focused Approach:",
          desc: "We listen to your needs, understand your vision, and work closely with you to turn your ideas into reality."
        },
        {
          title: "Innovative Solutions:",
          desc: "Using the latest tools and methodologies, we ensure that every project we undertake is equipped with the most modern and effective solutions."
        },
        {
          title: "Quality Assurance:",
          desc: "Every detail matters, which is why we maintain a rigorous quality control process to ensure that our work is flawless and durable."
        },
        {
          title: "Comprehensive Service:",
          desc: "Our expertise spans across multiple categories, ensuring that we can handle every aspect of your project, from planning and design to final approvals."
        }
      ]     
    },
    categories: [
      {
        title: `Blueprint Approvals`,
        desc: `Streamlining complex approval processes to help you navigate land regulatory compliance with ease.`,
        icon: `text_compare`,
        path: '/blueprint-approvals'
      },
      {
        title: '3D Design',
        desc: 'Offering high-quality interior and exterior 3D visualizations to bring your space to life before construction even begins.',
        icon: `3d_rotation`,
        path: '/design-3d'
      },
      {
        title: 'DTCP Layouts',
        desc: 'Creating layouts that meet all the required regulations, ensuring smooth approval processes and optimal land usage.',
        icon: `space_dashboard`,
        path: '/dtcp-layout'
      },
      {
        title: 'FMB Services',
        desc: 'Providing precise and official land measurement records to support legal, regulatory, and planning processes.',
        icon: `receipt_long`,
        path: '/fmb'
      }
    ],
    testimonials: {
      desc: `Our clients’ satisfaction is our top priority. Here’s what some of them have to say about working with us:`,
      feedback: [
        {
          desc: "SAN Planners & Designers transformed my ideas into a practical and beautiful design. Their professionalism and attention to detail were impressive.",
          name: "Karthick R",
          project: "Residential Project Client",
          img: ResidentialImg
        },
        {
          desc: "They made the whole process easy, from blueprint approvals to final design. I couldn’t have asked for a better experience.",
          name: "Kiruthika M",
          project: "Commercial Project Client",
          img: CommercialImg
        }
      ]
    },
    join: `At SAN Planners & Designers, we’re not just about delivering a service; we’re about creating an experience that lasts. Partner with us today and see how we can help bring your vision to life. Our team is ready to assist you in making your project a success from start to finish.`
  }



  return (
    <div>
      <div className='about-sections'>
        <h2 className="text-primary text-center fw-bolder fs-1 my-3">About Us</h2>
        <div className='d-flex flex-wrap gap-3 justify-content-center align-items-top'>
          <img className='rounded col-11 col-md-10' src={Logo} alt='SAN planners logo' style={{maxWidth: '500px', objectFit: 'contain'}} />
          <div className='col-11 col-md-10 col-lg-7 col-xl-5'>
            <p className='fs-5' style={{textAlign: 'justify'}}>{Content.about[0]}</p>
            <p className='fs-5' style={{textAlign: 'justify'}}>{Content.about[1]}</p>
            <button onClick={()=>navi('/contact')} className='btn btn-outline-primary rounded-pill fs-4 px-4'>Contact Us</button>
          </div>
        </div>
      </div>
      <AboutOffers Categories={Content.categories} />
      <AboutChoose choose={Content.whyChoose} />
      <AboutHistory history={Content.story} />
      <AboutTestimonials testimonials={Content.testimonials}/>
      <div className='about-sections col-11 col-md-10 col-lg-8 col-xl-6 mx-auto'>
        <h3 className="text-primary text-center fw-bolder fs-2">Join Us on Our Journey</h3>
        <div className='text-center'>
          <p className='fs-5' style={{textAlign: 'justify'}}>{Content.join}</p>
          <button onClick={()=>navi('/contact')} className='btn btn-primary fs-4 px-4 rounded-pill'>Enquire now</button>
        </div>
      </div>
    </div>
  )
}

export default About