import React from 'react'
import DImg from '../../../assets/structural.jpg'

const Construction = () => {
    
    const constructionManagement = {
        title: "Construction Management",
        desc: "From planning to execution, SAN Planners & Designers manage your construction projects to ensure they are completed on time, within budget, and with the highest quality standards. We handle everything from procurement to project supervision, so you can focus on your vision. Our team brings extensive experience in managing diverse projects, ensuring effective communication and coordination among all stakeholders.",
        features: [
            "Project Planning & Scheduling: Comprehensive planning to keep your project on track.",
            "Procurement & Material Management: Efficient procurement strategies for timely delivery of materials.",
            "Quality Control & Assurance: Rigorous quality checks at every stage of construction.",
            "Cost Estimation & Budgeting: Accurate cost estimates to help you manage your budget effectively.",
            "Site Supervision & Execution: On-site management to ensure work aligns with project specifications."
        ]
    };

  return (
    <div className='text-center'>
        <dl className='mt-5'>
            <dt><h3 className='text-primary fs-1 fw-bolder my-3'>{constructionManagement.title.toUpperCase()}</h3></dt>
            <dd className='d-flex flex-wrap gap-3 justify-content-center align-items-top'>
                <img className='service-img' src={DImg} alt='DTCP Layouts' />
                <div className='col-11 col-md-10 col-lg-8 col-xl-6'>
                    <p className='fs-5' style={{textAlign: 'justify'}}>{constructionManagement.desc}</p>
                    <ul className='text-start fs-5' style={{width: 'fit-content'}}>
                        {constructionManagement.features.map((feature, i)=>{
                            return(
                                <li key={i}>{feature}</li>
                            )
                        })}
                    </ul>
                </div>
            </dd>
        </dl>
    </div>
  )
}

export default Construction