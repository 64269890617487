import React from 'react'
import Design2D from '../../../assets/projects/2d/Img1.jpg'
import Design3D from '../../../assets/team1.jpg'
import { useNavigate } from 'react-router-dom'

const Design = () => {

  const navigatePage = useNavigate()

  const designs = [
    {
      title: "2D Design",
      desc: "Our 2D design services provide precise layouts and plans, offering clear and detailed visualizations of your project's structure and flow.",
      img: Design2D,
      path: '/design-2d'
    },
    {
      title: "3D Design",
      desc: "Our 3D design services bring concepts to life with immersive visualizations, helping you envision the final outcome before execution.",
      img: Design3D,
      path: '/design-3d'
    }
  ]

  return (
    <div className='pt-5'>
        <h2 className='fs-1 text-center text-primary my-3' style={{fontWeight: '900'}}>Design Services</h2>
        <p className='text-center fs-5 col-11 col-md-10 col-lg-9 col-xl-8 mx-auto'>Explore our comprehensive design services, tailored to bring your ideas to life with precision and creativity. Whether it's detailed 2D layouts or immersive 3D visualizations, we provide solutions that meet your project needs.</p>
        <div className='my-3 p-2 d-flex flex-wrap justify-content-center'>
            {
                designs.map((service, i)=>{
                    return(
                        <div className='service-card m-3' key={i} onClick={()=>navigatePage(service.path)} role='button'>
                            <div className='p-4'>
                              <h3 className='service-card-title text-primary fw-bolder fs-3'>{service.title}</h3>
                              <p className='service-card-desc m-0' style={{textAlign: 'justify'}}>{service.desc}</p>
                            </div>
                            <div className='w-100 service-card-img-layer-container'>
                                <div className='service-card-img-layer1'></div>
                                <div className='service-card-img-layer2'></div>
                                <div className='service-card-img-layer3'>
                                  {/* style={{maskImage: `url(${service.img})`}} */}
                                      <img className='service-card-img-layer3-inner' src={service.img} alt='SAN Planners Service' />
                                </div>
                            </div>
                            <div className='service-btn-container bg-light'>
                              <div className='w-100 h-100 d-flex justify-content-center align-items-center'>  
                                <i className='bi bi-arrow-up-right service-btn' onClick={()=>navigatePage(service.path)}></i>
                              </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    </div>
  )
}

export default Design