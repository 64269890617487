import React, { useContext } from 'react'
import { Dcontext } from '../../context/DataContext'

const Carousel = () => {

    const {renderImageIndex, renderImages} = useContext(Dcontext)

  return (
    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
            {/* <div className="carousel-item active">
            <img src="..." className="d-block w-100" alt="..."/>
            </div> */}



            {
                Array.isArray(renderImages) &&renderImages.map((img, i)=>{
                    return(
                        <div className={`carousel-item ${i===renderImageIndex?'active':''} `} key={i}>
                            <img src={img.img} className="d-block carousel-img" alt={img.title}/>
                        </div>
                    )
                })
            }



        </div>
        <div className='position-fixed top-50 d-flex justify-content-between w-100' style={{zIndex: '1009'}}>
            <i className='bi bi-arrow-left-circle-fill fs-1 text-light m-3 carousel-btn' role='button' data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev"></i>
            <i className='bi bi-arrow-right-circle-fill fs-1 text-light m-3 carousel-btn' role='button' data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next"></i>
        </div>
        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button> */}
    </div>
  )
}

export default Carousel