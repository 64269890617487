import React from 'react'
import Gallery from '../../blocks/Gallery/Gallery'

import Img1 from '../../../assets/projects/interior/Img1.jpg'
import Img2 from '../../../assets/projects/interior/Img2.jpg'
import Img3 from '../../../assets/projects/interior/Img3.jpg'
import Img4 from '../../../assets/projects/interior/Img4.jpg'
import Img5 from '../../../assets/projects/interior/Img5.jpg'
import Img6 from '../../../assets/projects/interior/Img6.jpg'
import Img7 from '../../../assets/projects/interior/Img7.jpg'
import Img8 from '../../../assets/projects/interior/Img8.jpg'
import Img9 from '../../../assets/projects/interior/Img9.jpg'
import Img10 from '../../../assets/projects/interior/Img10.jpg'
import Img11 from '../../../assets/projects/interior/Img11.jpg'
import Img12 from '../../../assets/projects/interior/Img12.jpg'
import Img13 from '../../../assets/projects/interior/Img13.jpg'
import Img14 from '../../../assets/projects/interior/Img14.jpg'
import Img15 from '../../../assets/projects/interior/Img15.jpg'
import Img16 from '../../../assets/projects/interior/Img16.jpg'
import Img17 from '../../../assets/projects/interior/Img17.jpg'
import Img18 from '../../../assets/projects/interior/Img18.jpg'
import Img19 from '../../../assets/projects/interior/Img19.jpg'
import Img20 from '../../../assets/projects/interior/Img20.jpg'
import Img21 from '../../../assets/projects/interior/Img21.jpg'
import Img22 from '../../../assets/projects/interior/Img22.png'
import Img23 from '../../../assets/projects/interior/Img23.jpg'
import Img24 from '../../../assets/projects/interior/Img24.jpg'
import Img25 from '../../../assets/projects/interior/Img25.jpg'
import Img26 from '../../../assets/projects/interior/Img26.jpg'
import Img27 from '../../../assets/projects/interior/Img27.jpg'


const Interior = () => {

  const Galleries = [
    {
      img: Img1,
      title: 'Interior Design Image 1',
    },
    {
      img: Img2,
      title: 'Interior Design Image 2',
    },
    {
      img: Img3,
      title: 'Interior Design Image 3',
    },
    {
      img: Img4,
      title: 'Interior Design Image 4',
    },
    {
      img: Img5,
      title: 'Interior Design Image 5',
    },
    {
      img: Img6,
      title: 'Interior Design Image 6',
    },
    {
      img: Img7,
      title: 'Interior Design Image 7',
    },
    {
      img: Img8,
      title: 'Interior Design Image 8',
    },
    {
      img: Img9,
      title: 'Interior Design Image 9',
    },
    {
      img: Img10,
      title: 'Interior Design Image 10',
    },
    {
      img: Img11,
      title: 'Interior Design Image 11',
    },
    {
      img: Img12,
      title: 'Interior Design Image 12',
    },
    {
      img: Img13,
      title: 'Interior Design Image 13',
    },
    {
      img: Img14,
      title: 'Interior Design Image 14',
    },
    {
      img: Img15,
      title: 'Interior Design Image 15',
    },
    {
      img: Img16,
      title: 'Interior Design Image 16',
    },
    {
      img: Img17,
      title: 'Interior Design Image 17',
    },
    {
      img: Img18,
      title: 'Interior Design Image 18',
    },
    {
      img: Img19,
      title: 'Interior Design Image 19',
    },
    {
      img: Img20,
      title: 'Interior Design Image 20',
    },
    {
      img: Img21,
      title: 'Interior Design Image 21',
    },
    {
      img: Img22,
      title: 'Interior Design Image 22',
    },
    {
      img: Img23,
      title: 'Interior Design Image 23',
    },
    {
      img: Img24,
      title: 'Interior Design Image 24',
    },
    {
      img: Img25,
      title: 'Interior Design Image 25',
    },
    {
      img: Img26,
      title: 'Interior Design Image 26',
    },
    {
      img: Img27,
      title: 'Interior Design Image 27',
    }
  ]

  return (
    <div>
        <h2 className='text-primary fs-1 fw-bolder mt-5 text-center'>Interior Design Projects</h2>
        <p className='fs-5 col-11 col-md-10 col-lg-8 col-xl-6 mx-auto' style={{textAlign: 'justify'}}>View our stunning interior design projects, showcasing unique spaces tailored to our clients' preferences, blending style and functionality.</p>
        <Gallery Images={Galleries}/>
    </div>
  )
}

export default Interior