import React from 'react'
import Blueprints from '../../blocks/Gallery/Gallery'

import Img1 from '../../../assets/projects/blueprint/img1.jpg'
import Img2 from '../../../assets/projects/blueprint/img2.jpg'
import Img3 from '../../../assets/projects/blueprint/img3.jpg'
import Img4 from '../../../assets/projects/blueprint/img4.jpg'
import Img5 from '../../../assets/projects/blueprint/img5.jpg'
import Img6 from '../../../assets/projects/blueprint/img6.jpg'
import Img7 from '../../../assets/projects/blueprint/img7.jpg'
import Img8 from '../../../assets/projects/blueprint/img8.jpg'
import Img9 from '../../../assets/projects/blueprint/img9.jpg'
import Img10 from '../../../assets/projects/blueprint/img10.jpg'
import Img11 from '../../../assets/projects/blueprint/img11.jpg'
import Img12 from '../../../assets/projects/blueprint/img12.jpg'
import Img13 from '../../../assets/projects/blueprint/img13.jpg'
import Img14 from '../../../assets/projects/blueprint/img14.jpg'
import Img15 from '../../../assets/projects/blueprint/img15.jpg'
import Img16 from '../../../assets/projects/blueprint/img16.jpg'
import Img17 from '../../../assets/projects/blueprint/img17.jpg'

const Blueprint = () => {


  const Gallery = [
    {
      img: Img1,
      title: 'Blueprint Image 1',
    },
    {
      img: Img2,
      title: 'Blueprint Image 2',
    },
    {
      img: Img3,
      title: 'Blueprint Image 3',
    },
    {
      img: Img4,
      title: 'Blueprint Image 4',
    },
    {
      img: Img5,
      title: 'Blueprint Image 5',
    },
    {
      img: Img6,
      title: 'Blueprint Image 6',
    },
    {
      img: Img7,
      title: 'Blueprint Image 7',
    },
    {
      img: Img8,
      title: 'Blueprint Image 8',
    },
    {
      img: Img9,
      title: 'Blueprint Image 9',
    },
    {
      img: Img10,
      title: 'Blueprint Image 10',
    },
    {
      img: Img11,
      title: 'Blueprint Image 11',
    },
    {
      img: Img12,
      title: 'Blueprint Image 12',
    },
    {
      img: Img13,
      title: 'Blueprint Image 13',
    },
    {
      img: Img14,
      title: 'Blueprint Image 14',
    },
    {
      img: Img15,
      title: 'Blueprint Image 15',
    },
    {
      img: Img16,
      title: 'Blueprint Image 16',
    },
    {
      img: Img17,
      title: 'Blueprint Image 17',
    }
  ]

  return (
    <div>
        <h2 className='text-primary fs-1 fw-bolder mt-5 text-center'>Blueprint Projects</h2>
        <p className='fs-5 col-11 col-md-10 col-lg-8 col-xl-6 mx-auto' style={{textAlign: 'justify'}}>Explore our precise and detailed blueprints that form the backbone of our successful construction projects. Browse through our portfolio of foundational designs.</p>
        <Blueprints Images={Gallery}/>
    </div>
  )
}

export default Blueprint