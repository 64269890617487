import React from 'react'
import DImg from '../../../assets/dtcp.jpg'

const DTCP = () => {
    
    const dtcpLayouts = {
        title: "DTCP Layouts & Approvals",
        desc: "SAN Planners & Designers ensure that your residential and industrial layouts comply with DTCP (Directorate of Town and Country Planning) regulations, ensuring access to roads, proper subdivision, and the necessary amenities for a well-planned environment. We handle every step of the approval process to make it easy for you. Our experienced team is well-versed in local regulations and can help you avoid common pitfalls in the layout approval process.",
        features: [
            "Residential & Industrial DTCP Layout Approval: Comprehensive services for both residential and industrial developments.",
            "Road Width Guidelines and Hierarchy: Expert advice on road widths and layouts based on DTCP standards.",
            "Public and Private Passage Conditions: Guidance on the requirements for public and private roadways.",
            "Open Space Reservation (OSR) Management: Help with reserving land for public amenities and green spaces.",
            "Compliance with Building & Layout Regulations: Assurance that all layouts meet current legal standards."
        ]
    };

  return (
    <div className='text-center'>
        <dl className='mt-5'>
            <dt><h3 className='text-primary fs-1 fw-bolder my-3'>{dtcpLayouts.title.toUpperCase()}</h3></dt>
            <dd className='d-flex flex-wrap gap-3 justify-content-center align-items-top'>
                <img className='service-img' src={DImg} alt='DTCP Layouts' />
                <div className='col-11 col-md-10 col-lg-8 col-xl-6'>
                    <p className='fs-5' style={{textAlign: 'justify'}}>{dtcpLayouts.desc}</p>
                    <ul className='text-start fs-5' style={{width: 'fit-content'}}>
                        {dtcpLayouts.features.map((feature, i)=>{
                            return(
                                <li key={i}>{feature}</li>
                            )
                        })}
                    </ul>
                </div>
            </dd>
        </dl>
    </div>
  )
}

export default DTCP