import React from 'react'
import DImg from '../../../assets/team1.jpg'

const Design2D = () => {
    
    const design2D = {
        title: "2D Design",
        desc: "At SAN Planners & Designers, our 2D design services encompass a range of architectural drawings and visualizations to aid in the planning and execution of your construction projects. We specialize in creating detailed floor plans, elevations, and technical drawings that are essential for both project approvals and contractor guidance. Our experienced designers ensure that every detail is accurately represented, facilitating seamless communication between stakeholders.",
        features: [
            "Architectural Floor Plans: Comprehensive plans that detail the layout of spaces.",
            "Elevations & Sections: Accurate elevations and cross-sections for complete project understanding.",
            "Technical Drawings: Detailed drawings that provide essential information for contractors.",
            "Vastu Plan: Customized designs that adhere to Vastu Shastra principles, promoting harmony and positive energy.",
            "Plumbing and Electrical Design: Efficient and compliant designs for plumbing and electrical systems to ensure safety and functionality.",
            "Design Modifications: Flexible services to accommodate design changes and revisions.",
            "Collaboration with Engineers & Contractors: Ensuring all drawings meet engineering specifications and contractor needs."
        ]
    };

  return (
    <div className='text-center'>
        <dl className='mt-5'>
            <dt><h3 className='text-primary fs-1 fw-bolder my-3'>{design2D.title.toUpperCase()}</h3></dt>
            <dd className='d-flex flex-wrap gap-3 justify-content-center align-items-top'>
                <img className='service-img' src={DImg} alt='DTCP Layouts' />
                <div className='col-11 col-md-10 col-lg-8 col-xl-6'>
                    <p className='fs-5' style={{textAlign: 'justify'}}>{design2D.desc}</p>
                    <ul className='text-start fs-5'>
                        {design2D.features.map((feature, i)=>{
                            return(
                                <li key={i}>{feature}</li>
                            )
                        })}
                    </ul>
                </div>
            </dd>
        </dl>
    </div>
  )
}

export default Design2D