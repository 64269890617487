import React from 'react'
import DTCPImg from '../../assets/dtcp approval.jpg'
import ConstructionImg from '../../assets/construction.jpg'
import DesignImg from '../../assets/designing.jpg'
import StructuralImg from '../../assets/structural.jpg'
import BlueprintImg from '../../assets/blueprint approval.jpg'
import EstimationImg from '../../assets/estimation.jpg'
import DocumentationImg from '../../assets/documentation.jpg'
import FmbImg from '../../assets/fmb.jpg'
import { useNavigate } from 'react-router-dom'

const ServiceBlock = () => {

  const navigatePage = useNavigate()

    const services = [
  
        {
          title: "Blueprint Approvals",
          desc: "Our team simplifies the blueprint approval process, guiding you through the necessary documentation and compliance for your project.",
          img: BlueprintImg,
          path: '/blueprint-approvals'
        },
        {
          title: "DTCP Layouts & Approvals",
          desc: "We facilitate smooth DTCP layout approvals to ensure your project complies with all regulatory requirements.",
          img: DTCPImg,
          path: '/dtcp-layout'
        },
        {
          title: "Design (2D & 3D)",
          desc: "We offer innovative design solutions, including 3D and 2D designs, to create spaces that reflect your unique style and functionality.",
          img: DesignImg,
          path: '/design'
        },{
          title: "Structural Design",
          desc: "We provide reliable structural design services, ensuring your building's safety, stability, and compliance with engineering standards.",
          img: StructuralImg,
          path: '/structural-design'
        },
        {
          title: "Construction",
          desc: "Our construction services encompass project management and consulting, ensuring your build is completed on time and within budget.",
          img: ConstructionImg,
          path: '/construction'
        },
        {
          title: "Estimating & Costing",
          desc: "We provide accurate estimating and costing services to help you budget effectively for your construction and design projects.",
          img: EstimationImg,
          path: '/estimation'
        },
        {
          title: "Document",
          desc: "Our document services include essential certificates and legal documents to ensure your property transactions are smooth and compliant.",
          img: DocumentationImg,
          path: '/documentation'
        },
        {
          title: "FMB (Sub Division)",
          desc: "We offer precise Field Measurement Book (FMB) services to accurately document land measurements for construction and planning needs.",
          img: FmbImg,
          path: '/fmb'
        }
      ];
      

  return (
    <div className='pt-5'>
        <h2 className='fs-1 text-center text-primary my-3' style={{fontWeight: '900'}}>Our Services</h2>
        <p className='text-center fs-5 col-11 col-md-10 col-lg-9 col-xl-8 mx-auto'>We offer DTCP layouts, construction management, design services, and blueprint approvals. Our team ensures creative solutions and seamless execution to bring your vision to life with precision.</p>
        <div className='my-3 p-2 d-flex flex-wrap justify-content-center'>
            {
                services.map((service, i)=>{
                    return(
                        <div className='service-card m-3' key={i} onClick={()=>navigatePage(service.path)} role='button'>
                            <div className='p-4'>
                              <h3 className='service-card-title text-primary fw-bolder fs-3'>{service.title}</h3>
                              <p className='service-card-desc m-0 fs-5' style={{textAlign: 'justify'}}>{service.desc}</p>
                            </div>
                            <div className='w-100 service-card-img-layer-container'>
                                <div className='service-card-img-layer1'></div>
                                <div className='service-card-img-layer2'></div>
                                <div className='service-card-img-layer3'>
                                  {/* style={{maskImage: `url(${service.img})`}} */}
                                      <img className='service-card-img-layer3-inner' src={service.img} alt='SAN Planners Service' />
                                </div>
                            </div>
                            <div className='service-btn-container bg-light'>
                              <div className='w-100 h-100 d-flex justify-content-center align-items-center'>  
                                <i className='bi bi-arrow-up-right service-btn' onClick={()=>navigatePage(service.path)}></i>
                              </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    </div>
  )
}

export default ServiceBlock