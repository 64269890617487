import React from 'react'
import ServiceBlock from '../blocks/ServiceBlock'

const Services = () => {
  return (
    <div>
      <ServiceBlock/>
    </div>
  )
}

export default Services