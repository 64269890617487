import React from 'react'
import Gallery from '../../blocks/Gallery/Gallery'

import Img1 from '../../../assets/projects/2d/Img1.jpg'
import Img2 from '../../../assets/projects/2d/Img2.jpg'
import Img3 from '../../../assets/projects/2d/Img3.jpg'
import Img4 from '../../../assets/projects/2d/Img4.jpg'
import Img5 from '../../../assets/projects/2d/Img5.jpg'
import Img6 from '../../../assets/projects/2d/Img6.png'

const Design2D = () => {

  const Galleries = [
    {
      img: Img1,
      title: '2D Design Image 1',
    },
    {
      img: Img2,
      title: '2D Design Image 2',
    },
    {
      img: Img3,
      title: '2D Design Image 3',
    },
    {
      img: Img4,
      title: '2D Design Image 4',
    },
    {
      img: Img5,
      title: '2D Design Image 5',
    },
    {
      img: Img6,
      title: '2D Design Image 6',
    }
  ]

  return (
    <div>
        <h2 className='text-primary fs-1 fw-bolder mt-5 text-center'>2D Visualization Projects</h2>
        <p className='fs-5 col-11 col-md-10 col-lg-8 col-xl-6 mx-auto' style={{textAlign: 'justify'}}>Step into our 2D visualizations and explore projects brought to life with detailed models, giving you a glimpse of the final look before construction.</p>
        <Gallery Images={Galleries}/>
    </div>
  )
}

export default Design2D