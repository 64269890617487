import React from "react";
import { useNavigate } from "react-router-dom";
import TestimonialsSlider from './TestimonialsSliders'

function Testimonials() {

    const navPage = useNavigate()


  return (
    <div className=" mt-5">
        <h2 className='fs-1 text-center text-primary my-3' style={{fontWeight: '900'}}>What Our Clients Say</h2>
        <p className='text-center fs-5 col-11 col-md-10 col-lg-9 col-xl-8 mx-auto' >At SAN Planners & Designers, our clients’ satisfaction is our top priority. Here’s what they have to say about their experience working with us.</p>
        <TestimonialsSlider/>
        <div className='d-flex justify-content-center'>
          <button className='btn btn-primary fs-5 px-5 rounded-pill mb-5' onClick={()=>navPage('/projects')}>View Projects</button>
        </div>
    </div>
  );
}

export default Testimonials;
