import React from 'react'

function ServiceZones() {

    const municipalities = [
        'Gobichettipalayam',
        'Bhavani',
        'Sathyamangalam'
    ]

    const DTCPDistrictOffice = [
        'Erode',
        'Tiruppur',
        'Coimbatore',
        'Salem',
        'Namakkal'
    ]

    const Thaluks = [
        'Perundurai',
        'Gobichettipalayam',
        'Bhavani',
        'Sathyamangalam',
        'Anthiyur',
        'Erode',
        'Uthukuli'
    ]

    const subRegistrar = [
        'Perundurai',
        'Chennimalai',
        'Thingalur',
        'Uthukuli',
        'Erode - Joint I',
        'Sathyamangalam',
        'Kavindapadi',
        'Anthiyur',
        'Thookanaickenpalayam',
        'Gobi - Joint I',
        'Gobi - Joint II',
        'Kunnathur',
        'Kangeyam',
        'Avinashi'
    ]

    const Unions = [
        'Perundurai',
        'Uthukuli',
        'Chennimalai',
        'Gobichettipalayam',
        'Bhavani',
        'Sangagiri',
    ]

    const townPanchayats = [
        'Perundurai',
        'Karumandi Chellipalayam',
        'Chennimalai',
        'Appakudal',
        'Kanjikoil',
        'Pethampalayam',
        'Salangapalayam',
        'Kolappalur',
        'Lakkampatti',
        'Athani',
        'Anthiyur',
        'Nasiyanur',
        'Chithode',
        'Ariyappampalayam'
    ]

    const villages = [
        'Kambiliyampatti',
        'Vijayapuri(Vijayamangalam)',
        'Mettupudur',
        'Moongil Palayam',
        'Sullipalayam',
        'Seenapuram',
        'Pattakaranpalayam',
        'Pappampalayam',
        'Thiruvachi',
        'Ponmudi',
        'Nadupatti',
        'Vellaraveli',
        'Ingur',
        'Mukasipidariyur',
        'Siruvalur',
        'Kavindapadi'
    ]



  return (
    <div style={{minHeight: '70vh'}}>
        <h2 className="text-primary text-center fw-bolder fs-1 my-3">Our Service Zones</h2>
        <div className='lists-container '>
            <div className='lists-container-block'>
                <div className='list-container'>
                    <h3>Corporation (1+)</h3>
                    <ul>
                        <li>Erode</li>
                        <li><i>& more</i></li>
                    </ul>
                </div>
                <div className='list-container'>
                    <h3>Municipality (3+)</h3>
                    <ul>
                        {
                            municipalities.map((mItem, i)=>{
                                return(
                                    <li key={i}>{mItem}</li>
                                )
                            })
                        }
                        <li><i>& more</i></li>
                    </ul>
                </div>
                <div className='list-container'>
                    <h3>DTCP - District Offices (5+)</h3>
                    <ul>
                        {
                            DTCPDistrictOffice.map((Item, i)=>{
                                return(
                                    <li key={i}>{Item}</li>
                                )
                            })
                        }
                        <li><i>& more across TamilNadu</i></li>
                    </ul>
                </div>
            </div>
            <div>
                <div className='list-container'>
                    <h3>Taluka (7+)</h3>
                    <ul>
                        {
                            Thaluks.map((item, i)=>{
                                return(
                                    <li key={i}>{item}</li>
                                )
                            })
                        }
                        <li><i>& more</i></li>
                    </ul>
                </div>
                <div className='list-container'>
                    <h3>Union Offices (6+)</h3>
                    <ul>
                        {
                            Unions.map((item, i)=>{
                                return(
                                    <li key={i}>{item}</li>
                                )
                            })
                        }
                        <li><i>& more</i></li>
                    </ul>
                </div>
            </div>
            <div className='list-container'>
                <h3>Sub-Registrar Office (14+)</h3>
                <ul>
                    {
                        subRegistrar.map((item, i)=>{
                            return(
                                <li key={i}>{item}</li>
                            )
                        })
                    }
                    <li><i>& more</i></li>
                </ul>
            </div>
            <div className='list-container'>
                <h3>Town Panchayat (14+)</h3>
                <ul>
                    {
                        townPanchayats.map((item, i)=>{
                            return(
                                <li key={i}>{item}</li>
                            )
                        })
                    }
                    <li><i>& more</i></li>
                </ul>
            </div>
            <div className='list-container'>
                <h3>Villages (143+)</h3>
                <ul>
                    {
                        villages.map((item, i)=>{
                            return(
                                <li key={i}>{item}</li>
                            )
                        })
                    }
                    <li><i>& 127 more</i></li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default ServiceZones