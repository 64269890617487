import React from 'react'
import DImg from '../../../assets/landing-removebg-large.png'

const Design3D = () => {
    
    const design3D = {
        title: "3D Design",
        desc: "At SAN Planners & Designers, we believe that every space tells a story. Our mission is to craft unique and inspiring interiors that reflect your personality, enhance functionality, and elevate the aesthetics of your home or office. Our 3D rendering and visualization services bring your designs to life, ensuring every detail is captured with precision. We collaborate closely with clients to tailor solutions that align with their vision, budget, and lifestyle.",
        features: [
            "Residential & Commercial Buildings: Comprehensive design solutions for various building types.",
            "Customized Interior Design Solutions: Tailored designs that reflect your personal style and needs.",
            "Interior Renovation: Transform your existing spaces with innovative renovation designs.",
            "3D Rendering & Visualization: High-quality visualizations to help clients envision their projects.",
            "Exterior Design Services: Enhance your property's curb appeal with thoughtful exterior designs."
        ]
    };

  return (
    <div className='text-center'>
        <dl className='mt-5'>
            <dt><h3 className='text-primary fs-1 fw-bolder my-3'>{design3D.title.toUpperCase()}</h3></dt>
            <dd className='d-flex flex-wrap gap-3 justify-content-center align-items-top'>
                <img className='service-img' src={DImg} alt='DTCP Layouts' />
                <div className='col-11 col-md-10 col-lg-8 col-xl-6'>
                    <p className='fs-5' style={{textAlign: 'justify'}}>{design3D.desc}</p>
                    <ul className='text-start fs-5' style={{width: 'fit-content'}}>
                        {design3D.features.map((feature, i)=>{
                            return(
                                <li key={i}>{feature}</li>
                            )
                        })}
                    </ul>
                </div>
            </dd>
        </dl>
    </div>
  )
}

export default Design3D