import React from 'react';
import ReactDOM from 'react-dom/client';
import DataContext from './context/DataContext';
import { BrowserRouter } from 'react-router-dom';
import './css/index.css';
import App from './App';
import './i18n';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <DataContext>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </DataContext>
);

