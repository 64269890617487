import React from 'react'
import chooseImg from '../../../assets/undraw_stand_out_-1-oag.svg'
// import chooseImg from '../../../assets/teamai.jpg'
// import listIcon from '../../../assets/right-arrow (2).png'

const AboutChoose = (props) => {

    const Content = props.choose

  return (
    <div className='about-sections'>
        <h3 className="text-primary text-center fw-bolder fs-2">Why Choose us?</h3>
        <div className='d-flex gap-3 flex-wrap justify-content-evenly align-items-center'>
            <img className='rounded col-11 col-md-10 col-lg-4 col-xl-4 why-choose-img' src={chooseImg} alt='Features' />
            <div className='col-11 col-md-10 col-lg-6 col-xl-5 py-3 px-2'>
                <p className='fs-5' style={{textAlign: 'justify'}}>{Content.desc}</p>
                <ul className='fs-5 why-choose-list' style={{textAlign: 'justify'}} >
                {/* <ul className='fs-5' style={{textAlign: 'justify', listStyleImage: `url(${listIcon})`}} > */}
                {
                    Content.features.map((item, i)=>{
                    return(
                        <li key={i}><b className='text-primary'>{item.title}</b> {item.desc}</li>
                    )
                    })
                }
                </ul>
            </div>
        </div>
    </div>
  )
}

export default AboutChoose