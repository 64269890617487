import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

const Projects = () => {

  const navPage = useNavigate()


  const projectCategories = [
    {
      title: "Blueprint",
      description: "Precision-driven and carefully crafted, our blueprints lay the foundation for successful and efficient construction projects.",
      icon: `text_compare`,
      path: '/project-blueprint'
    },
    {
      title: "DTCP",
      description: "Compliance and creativity combine in our DTCP-approved designs, ensuring both regulatory approval and aesthetic appeal.",
      icon: 'space_dashboard',
      path: '/project-dtcp'
    },
    {
      title: "Interior Designing",
      description: "From concept to completion, we create functional, beautiful interiors that reflect the client's personality and lifestyle.",
      icon: 'room_preferences',
      path: '/project-interior'
    },
    {
      title: "Exterior Designing",
      description: "Transforming exteriors into visually stunning and welcoming spaces, combining functionality with aesthetic charm.",
      icon: 'house_siding',
      path: '/project-exterior'
    },
    {
      title: "2D Design",
      description: "High-quality 2D models and visualizations that bring design concepts to life, allowing clients to envision the final outcome.",
      icon: `2d`,
      path: '/project-2d'
    },
    {
      title: "FMB (Sub Division)",
      description: "Precise and official land measurement records to support legal, regulatory, and planning processes.",
      icon: `article`,
      path: '/project-fmb'
    }
  ];

  

  return (
    <div>
      <h2 className='text-primary fs-1 fw-bolder mt-5 text-center'>Our Projects</h2>
      <p className='fs-5 col-11 col-md-10 col-lg-8 col-xl-6 mx-auto' style={{textAlign: 'justify'}}>Explore our portfolio of completed projects across various categories. We take pride in delivering innovative design solutions tailored to meet the unique needs of each client. Below are the categories showcasing some of our finest work.</p>
      <div className='d-flex flex-wrap gap-3 justify-content-center align-items-stretch my-3'>
        {
          projectCategories.map((project, i)=>{
            return(
              <div id='project-card' className='about-offers-card p-5 col-11 col-md-10 col-lg-4 col-xl-3' key={i} role='button' onClick={()=>navPage(project.path)}>
                  <div className='d-flex justify-content-center align-items-center'>
                      <span className='about-offers-card-icon d-flex justify-content-center align-items-center'>
                          <span className="material-symbols-outlined fs-1 text-primary">
                          {project.icon}
                          </span>
                      </span>
                  </div>
                  <h4 className='my-3 text-primary'>{project.title}</h4>
                  <p className='fs-5' style={{textAlign: 'justify'}}>{project.description}</p>
                  <Link className='fs-5' to={project.path}>View Projects</Link>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Projects