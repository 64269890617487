import React, {useEffect} from 'react'
import jobIllus from '../../assets/intern.jpg'
// import jobIllus from '../../assets/cartoon-business.png'

const Careers = () => {


  // const formRef = useRef('')
  // const nameRef = useRef('')
  // const emailRef = useRef('')
  // const contactRef = useRef('')
  // const positionRef = useRef('')
  // const coverRef = useRef('')

  const jobs = [
    {
      title: "Draftsman (CAD Technician)",
      icon: "🖥️",
      description: "Prepare detailed drawings using CAD software, ensuring accuracy in collaboration with engineers and architects."
    },
    {
      title: "Interior & Exterior Designer",
      icon: "🎨",
      description: "Create functional and attractive interiors by understanding client needs and selecting appropriate materials."
    },
    {
      title: "3D Visualizer/ Modeler",
      icon: "📐",
      description: "Produce 3D models and visualizations for presentations, working closely with design teams to convey concepts."
    },
    {
      title: "Structural Engineer",
      icon: "🔧",
      description: "Design and oversee the construction of safe structures, collaborating with architects and contractors."
    }
    // {
    //   title: "Landscape Architect",
    //   icon: "🌳",
    //   description: "Design outdoor spaces like parks and gardens, balancing environmental sustainability with aesthetic appeal."
    // }
  ];

  // const handleSubmission = (e) => {

  //   e.preventDefault()

  //   formRef.current.classList.add("was-validated")

  //   if(nameRef.current.value!==''&&emailRef.current.value!==''&&contactRef.value!==''&&positionRef.current.value!==''){
  //     console.log(nameRef.current.value, emailRef.current.value, contactRef.current.value, positionRef.current.value, coverRef.current.value)
  //   }

    
  // }

  useEffect(()=>{
    const containerImg = document.getElementById("internship-career-content")
    const Img = document.getElementById("Intern-img")

    if(containerImg){
        console.log('c',containerImg)
        Img.classList.add("visible")
    }

  },[])


  

  return (
    <div className='my-3'>
      <h2 className='text-primary fs-1 fw-bolder mt-5 text-center'>Internship Opportunities at SAN Planners & Designers</h2>
      <p className='fs-5 col-11 col-md-10 col-lg-8 col-xl-6 mx-auto' style={{textAlign: 'justify'}}>At SAN Planners & Designers, our areas of expertise include innovative design solutions in</p>
      <div className='d-flex flex-wrap gap-3 justify-content-evenly align-items-center my-2' id='internship-career-content'>
        <img className='col-10 col-md-5 col-lg-4 col-xl-3 career-im' src={jobIllus} alt='Interviewing Illustration' id="Intern-img" />
        <div className='col-11 col-md-10 col-lg-5 col-xl-4'>
          {
            jobs.map((job, i)=>{
              return(
                <div className='m-2 job-card p-3' key={i} role='button' onClick={()=>window.open("https://forms.gle/CSxui6r5mdM5NZKt7", "_blank")}>
                  <span className='fs-3 m-2'>{job.icon}</span>
                  <div>
                    <h3 className='text-primary fs-4'>{job.title}</h3>
                    <p className='m-0 fs-5' style={{textAlign: 'justify'}}>{job.description}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>


      <div className='text-center'>
        <button className='btn btn-primary fs-5 px-4 rounded-pill' onClick={()=>window.open("https://forms.gle/CSxui6r5mdM5NZKt7", "_blank")}>Apply here</button>
      </div>



      {/* <div>
        <hr/>
        <h3 className='text-primary text-center fw-bolder'>Apply now</h3>
        <p className='fs-5 col-11 col-md-10 col-lg-8 col-xl-6 mx-auto' style={{textAlign: 'justify'}}>Passionate about architecture and design? Fill out the form below to apply for internships or jobs. We’ll reach you out if an opportunity arises.</p>
        <form ref={formRef} className='needs-validation col-12 col-md-12 col-lg-6 col-xl-6 mx-auto p-2 m-2 border rounded' noValidate onSubmit={(e)=>{handleSubmission(e)}}>
            <div className="mb-3">
              <label htmlFor="Input1" className="form-label" name="fullname">Full Name</label>
              <input ref={nameRef} type="text" className="form-control" id="Input1" placeholder="Enter your Name" required/>
              <div className="invalid-feedback">
                Please enter your Fullname.
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="Input2" className="form-label" name="email">Email address</label>
              <input ref={emailRef} type="email" className="form-control" id="Input2" placeholder="yourname@mail.com" required/>
              <div className="invalid-feedback">
                Please enter your Email.
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="Input3" className="form-label" name="contact">Contact Number</label>
              <input ref={contactRef} type="tel" className="form-control" id="Input3" placeholder="98765 43210" required/>
              <div className="invalid-feedback">
              Please enter your contact Number.
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="Input4" className="form-label" name="city">Position applying for</label>
              <input ref={positionRef} type="text" className="form-control" id="Input4" placeholder="Eg: CAD Designer" required/>
              <div className="invalid-feedback">
                Please select a position.
              </div>
            </div>
            <div className="input-group mb-3">
              <input type="file" className="form-control" id="inputGroupFile02" required/>
              <label className="input-group-text" htmlFor="inputGroupFile02">Resume</label>
              <div className="invalid-feedback">
                Please upload your Resume.
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="Textarea1" className="form-label" name="message">Cover Letter</label>
              <textarea ref={coverRef} className="form-control" id="Textarea1" rows="3" placeholder='Enter here'></textarea>
            </div>
            <button type='submit' className='btn btn-primary px-4 fs-5 rounded-pill m-2'>Submit</button>
          </form>
      </div> */}
    </div>
  )
}

export default Careers