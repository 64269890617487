import React from 'react'
import Gallery from '../../blocks/Gallery/Gallery'

import Img1 from '../../../assets/projects/FMB/Img1.jpg'
import Img2 from '../../../assets/projects/FMB/Img2.jpg'
import Img3 from '../../../assets/projects/FMB/Img3.jpg'

const Fmb = () => {

    const Galleries = [
        {
            img: Img1,
            title: 'FMB Image 1',
        },
        {
            img: Img2,
            title: 'FMB Image 2',
        },
        {
            img: Img3,
            title: 'FMB Image 3',
        }
    ]

  return (
    <div style={{minHeight: '60vh'}}>
        <h2 className='text-primary fs-1 fw-bolder mt-5 text-center'>FMB Projects</h2>
        <p className='fs-5 col-11 col-md-10 col-lg-8 col-xl-6 mx-auto' style={{textAlign: 'justify'}}>Provides precise and official land measurement records to support legal, regulatory, and planning processes.</p>
        <Gallery Images={Galleries}/>
    </div>
  )
}

export default Fmb